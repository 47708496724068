* {
}

.App {
  display: flex;
  /* overflow-x: hidden; */
  /* -webkit-overflow-scrolling: touch; */
}
/* 
body {
  overflow-x: hidden;
} */

.page-container {
  margin-top: 64px;
  flex-grow: 1;
}

h2:after {
  display: block;
  width: 80%;
  margin: 8px auto 4px auto;
  height: 1px;
  content: '';
}

.drawer-on-right {
  width: 480px;
}

.edit-dialog {
  width: 400px;
  padding: 16px;
}

@media only screen and (max-width: 480px) {
  .drawer-on-right {
    width: 320px;
  }
  
  .edit-dialog {
    width: 800px;
    padding: 16px;
  }
}

.paper-with-padding {
  padding: 16px;
}

.chip-with-margin {
  margin: 2px;
}
